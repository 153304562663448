  $widthS: 16px;
  $widthM: 32px;
  
  .productCard{
    cursor: pointer;
    @media screen and (min-width: 600px) {
      margin: 8px;
      width: calc( 50% - #{$widthS});
    }
    @media screen and (min-width: 960px) {
      margin: 16px;
      width: calc( 33.3333% -  #{$widthM} );
    }
    // &:hover{
    //   transform: scale(1.2);
    //   transition-duration: 0.3s;
    // }
  }

  .content{
    display: flex;
    padding: 16px 8px;
    text-align: left;
    background: #fbfbf5;
    border: transparent;
    &:last-child {
      padding-Bottom: 16px;
     }
  }
  .icon {
    margin-right: 0;
    margin-left: auto;
  }
  .media {
    height: 0;
    padding-top: 100%;
  }
  .price {
    color: #9e9e9e;
    font-size: 16px;
  }
  .productName {
    box-orient: vertical;
    display: -webkit-box;
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    @media screen and (min-width: 600px) {
        height: 36px;
        line-clamp: 2px;
    }
    @media screen and (min-width: 960px) {
        height: 18px;
        line-clamp: 1px;
    }
  }
  .MuiPaper-elevation1{
    box-shadow: none !important;
  }