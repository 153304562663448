.saveButton{
  width: 200px;
  height: 40px;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  color: #fff;
  background:#4d4d4d;
  margin-bottom: 20px;
  &:hover {
    background: #dfdfdf;
    border: 1px solid #dfdfdf;
    color: #4d4d4d;
  }

}
.backButton{
  width: 200px;
  height: 40px;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  color: #999;
  background:transparent;
  &:hover {
    border: 1px solid #999;
  }

}
